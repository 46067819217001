
import { ref, reactive, unref } from '@vue/reactivity'
import {
  SELECTALLCATEGORY,
  SELECTALLTAG,
  INSERTARTICLE,
  UPDATEARTICLE,
  SELECTARTICLEBYID
} from '../api/api'
import { onMounted, watch } from '@vue/runtime-core'
import Upload from '../components/Upload.vue'
import TEditor from '@/components/TEditor.vue'
import router from '../router/index'
import { useRoute } from 'vue-router'
import type { RuleObject } from 'ant-design-vue/es/form'

interface ArticleFrom {
  id: number
  title: string
  subtitle: string
  category_id: number
  tag: Array<number>
  tag_id: string
  cover_photo: string | string | string[]
  content: string
}
export default {
  components: {
    Upload,
    TEditor
  },
  props: {
    pageType: {
      type: String
    }
  },
  setup(props) {
    const upload = ref(null)
    const editor = ref(null)
    const route = useRoute()
    onMounted(() => {
      // 查询所有分类数据
      SELECTALLCATEGORY().then((response) => {
        if (response.code === 0) {
          categoryData.value = response.data
        }
      })
      // 查询所有标签数据
      SELECTALLTAG().then((response) => {
        if (response.code === 0) {
          tagData.value = response.data
        }
      })
      // 编辑文章做回填
      if (JSON.stringify(route.params) !== '{}') {
        console.log(route.params)
        SELECTARTICLEBYID({ id: route.params.id }).then((response) => {
          const {
            id,
            title,
            subtitle,
            category_id,
            tag_id,
            content,
            cover_photo
          } = response.data
          articleFrom.id = id
          articleFrom.title = title
          articleFrom.subtitle = subtitle
          articleFrom.category_id = category_id
          articleFrom.tag = tag_id.split(',').map(Number)
          articleFrom.content = content
          const photoUrl =
            'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'
          upload.value.fileList[0] = {
            thumbUrl: photoUrl + JSON.parse(cover_photo.toString())[0],
            name: JSON.parse(cover_photo.toString())[0]
          }
          upload.value.returnData = JSON.parse(cover_photo.toString())
        })
        // articleFrom.id = Number(route.params.id)
        // articleFrom.title = route.params.title.toString()
        // articleFrom.subtitle = route.params.subtitle.toString()
        // articleFrom.category_id = Number(route.params.category_id)
        // articleFrom.tag = JSON.parse(route.params.tag_id.toString())
        // articleFrom.content = route.params.content.toString()
        // const photoUrl =
        //   process.env.NODE_ENV === 'production'
        //     ? 'https://photo.yedeqin.com/'
        //     : 'http://127.0.0.1:8081/img/'
        // upload.value.fileList[0] = {
        //   thumbUrl:
        //     photoUrl + JSON.parse(route.params.cover_photo.toString())[0],
        //   name: JSON.parse(route.params.cover_photo.toString())[0]
        // }
        // upload.value.returnData = JSON.parse(
        //   route.params.cover_photo.toString()
        // )
      }
    })

    const categoryData = ref([]) // 分类数据
    const tagData = ref([]) // 标签数据
    const articleFromRef = ref()
    // 添加文章表单
    const articleFrom = reactive<ArticleFrom>({
      id: 0,
      title: '',
      subtitle: '',
      category_id: null,
      tag: [],
      tag_id: '',
      cover_photo: '',
      content: ''
    })
    // 新增文章方法
    const saveArticle = async () => {
      articleFrom.tag_id = articleFrom.tag.join()
      articleFrom.cover_photo = JSON.stringify(upload.value.returnData)
      const form = unref(articleFromRef)
      if (!form) return
      await form.validate()
      if (props.pageType === 'add') {
        // 新增文章
        await INSERTARTICLE(articleFrom).then((response) => {
          if (response.code === 0) {
            router.push({ name: 'Main' })
          }
        })
      } else if (props.pageType === 'edit') {
        // 编辑文章
        await UPDATEARTICLE(articleFrom).then((response) => {
          if (response.code === 0) {
            router.push({ name: 'Main' })
          }
        })
      }
    }
    // 是否上传图片表单验证规则
    const photoRules = async (_rule: RuleObject, value: string) => {
      if (value === '[]') {
        return Promise.reject(new Error('请上传封面图片'))
      } else {
        return Promise.resolve()
      }
    }
    // 监听文章内容改变，对其作表单验证
    watch(
      () => articleFrom.content,
      () => {
        const form = unref(articleFromRef)
        form.validateFields(['content'])
      }
    )
    // 是否使用图片名称上传图片
    const showInputImage = ref(false)
    // 图片名称
    const imageName = ref()
    // 提交图片名称
    const submitImage = () => {
      const photoUrl =
        'https://yedeqin-blog-1257421454.cos.ap-nanjing.myqcloud.com/'
      upload.value.fileList[0] = {
        thumbUrl: photoUrl + imageName.value,
        name: imageName.value
      }
      upload.value.returnData = [imageName.value]
      showInputImage.value = false
    }

    return {
      upload,
      editor,
      categoryData,
      tagData,
      articleFromRef,
      articleFrom,
      saveArticle,
      photoRules,
      showInputImage,
      imageName,
      submitImage
    }
  }
}
