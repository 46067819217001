
import { ref } from '@vue/reactivity'
import { UPLOADPHOTO } from '../api/api'
import { PlusOutlined } from '@ant-design/icons-vue'
import { onMounted } from '@vue/runtime-core'

export default {
  props: {
    photoLength: {
      type: Number
    }
  },
  components: {
    PlusOutlined
  },
  setup() {
    onMounted(() => {
      console.log('fileList', fileList.value)
    })
    const previewVisible = ref<boolean>(false)
    // const previewImage = ref<string | undefined>('')
    const fileList = ref([])
    const imageUrl = ref<string>('')
    // 组件返回图片数据
    const returnData = ref([])
    // 图片文件上传方法
    const customRequest = (param) => {
      console.log('param', param)
      var formData = new FormData()
      formData.append('file', param.file)
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
      UPLOADPHOTO(formData, config).then((response) => {
        param.onSuccess(response, param.file)
      })
    }
    // 修改图片触发方法
    const handleChange = (info) => {
      if (info.file.status === 'uploading') {
        return null
      } else {
        processingData(info.fileList)
      }
    }
    // 处理上传列表方法
    const processingData = (fileList) => {
      returnData.value = []
      for (var i = 0; i < fileList.length; i++) {
        returnData.value.push(fileList[i].response.data)
      }
    }
    const previewImage = ref('')
    const previewTitle = ref('')
    const handlePreview = async (file) => {
      previewImage.value = file.thumbUrl
      previewVisible.value = true
      previewTitle.value =
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    }

    // 关闭图片展示弹窗
    const handleCancel = () => {
      previewVisible.value = false
      previewTitle.value = ''
    }

    return {
      fileList,
      customRequest,
      handleChange,
      imageUrl,
      previewVisible,
      returnData,
      previewImage,
      previewTitle,
      handlePreview,
      handleCancel
    }
  }
}
